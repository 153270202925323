.device-mobile .page-navigation .level-1 {
  text-transform: capitalize;
}
/* font style */
@font-face {
  font-family: 'Arsenal';
  src: url('#{$netstorage-font-path}Arsenal/arsenal-regular.woff2') format('woff2');
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}

.header__headline,
.mpp__header,
.mpp__bestfor--headline,
.mb-slideshow__slide-content h2 {
  font-family: 'Arsenal' !important;
}

.brand-renutriv {
  .mpp__header,
  .header__headline {
    font-family: $bb-roman !important;
  }
}
